@use "@angular/material" as mat;
@use "sass:map";
@use "./variables";
@import "mixin.scss";


/** We must define first the font-families **/
@font-face {
  font-family: lucien-regular;
  src: url(../../assets/lib-common/fonts/lucien-regular.eot);
  src: url(../../assets/lib-common/fonts/lucien-regular.eot?#iefix) format("embedded-opentype"),
  url(../../assets/lib-common/fonts/lucien-regular.svg#lucien-regular) format("svg"),
  url(../../assets/lib-common/fonts/lucien-regular.woff) format("woff"), url(../../assets/lib-common/fonts/lucien-regular.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: lucien-book;
  src: url(../../assets/lib-common/fonts/lucien-book.eot);
  src: url(../../assets/lib-common/fonts/lucien-book.eot?#iefix) format("embedded-opentype"),
  url(../../assets/lib-common/fonts/lucien-book.svg#lucien-book) format("svg"), url(../../assets/lib-common/fonts/lucien-book.woff) format("woff"),
  url(../../assets/lib-common/fonts/lucien-book.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: lucien-light;
  src: url(../../assets/lib-common/fonts/lucien-light.eot);
  src: url(../../assets/lib-common/fonts/lucien-light.eot?#iefix) format("embedded-opentype"),
  url(../../assets/lib-common/fonts/lucien-light.svg#lucien-light) format("svg"), url(../../assets/lib-common/fonts/lucien-light.woff) format("woff"),
  url(../../assets/lib-common/fonts/lucien-light.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: lucien-bold;
  src: url(../../assets/lib-common/fonts/lucien-bold.eot);
  src: url(../../assets/lib-common/fonts/lucien-bold.eot?#iefix) format("embedded-opentype"),
  url(../../assets/lib-common/fonts/lucien-bold.svg#lucien-bold) format("svg"), url(../../assets/lib-common/fonts/lucien-bold.woff) format("woff"),
  url(../../assets/lib-common/fonts/lucien-bold.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
}

$my-primary: mat.define-palette(variables.$palette, 500); // 500 as default color
$my-accent: mat.define-palette(variables.$palette, A200, A100, A400); // secondary palette with $default, $lighter, $black3
$my-warn: mat.define-palette(mat.$red-palette); // The "warn" palette is optional and defaults to red if not specified.

// We update the typography
// Get the default typography
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($lucien-typography);`
// @include mat.all-legacy-component-typographies($lucien-typography);
// @include mat.legacy-core();


// // colors.scss
// $colors: (
//   'primary': '#333',
//   'alert': '#900',
//   'custom': '#090'
// );

// // This way of generating the color classes allow us to simplify how we add a new color.
// // We just should add the new color to $colors and we will have the color class
// @each $name,
// $color in $colors {
//   .bg-#{$name} {
//     background: $color;
//   }
// }

// @each $name,
// $color in $colors {
//   .#{$name} {
//     color: $color;
//   }
// }


@include mat.core();
// CZAM PALETTE
$czam-primary: mat.define-palette(variables.$palette, 500); // 500 as default color
$czam-accent: mat.define-palette(variables.$palette, A200, A100, A400); // secondary palette with $default, $lighter, $black3
$czam-warn: mat.define-palette(mat.$red-palette); // The "warn" palette is optional and defaults to red if not specified.
// CZAM TYPOGRAPHY
$typography-czam-config: mat.define-typography-config(
  $font-family: 'lucien-regular, serif',
  $headline-1: mat.define-typography-level(3rem,1.125, 400,lucien-book),
  $headline-2: mat.define-typography-level(2.5rem,1.125, 400,lucien-book),
  $headline-3: mat.define-typography-level(2rem,1.125, 400,lucien-book),
  $headline-4: mat.define-typography-level(1.8rem,1.125, 400,lucien-book),
  // h1
  $headline-5: mat.define-typography-level(1.8rem,1.125, 400,lucien-book),
  // h2
  $headline-6: mat.define-typography-level(1.4rem,1.125, 400,lucien-regular),
  // h3
  $subtitle-1: mat.define-typography-level(1.1rem,1.125, 400,lucien-regular),
  $subtitle-2: mat.define-typography-level(1.1rem,1.125, 400,lucien-regular),
  // form field
  $body-1: mat.define-typography-level(1.1rem,1.125, 400,lucien-regular),
  $body-2: null,
  $caption: null,
  $button: mat.define-typography-level(1.1rem,1.125rem, 600, lucien-book),
  $overline: null
);
@include mat.all-component-typographies($typography-czam-config);
// CZAM THEME CREATION
$czam-theme: mat.define-dark-theme((
  color: (
    primary: $czam-primary,
    accent: $czam-accent,
    warn: $czam-warn
  ),
  typography: $typography-czam-config,
  density:0
));
// UPDATE THE BACKGROUND
$color: map.get($czam-theme, "color");
$backgroundColor: map.get($color, "background");
$backgroundColor: map.set($backgroundColor, "background", variables.$black3);
$backgroundColor: map.set($backgroundColor, "app-bar", variables.$black1);
$color: map.set($color, "background", $backgroundColor);
$foregroundColor: map.get($color, "foreground");
$foregroundColor: map.set($foregroundColor, "text", variables.$gray);
$color: map.set($color, "foreground", $foregroundColor);
$czam-theme: map.set($czam-theme, "color", $color);
// APPLY CZAM THEME
@include mat.core-theme($czam-theme);
@include mat.all-component-themes($czam-theme);
// CZAM BUTTONS
$czam-button-theme: mat.define-dark-theme((color: (primary: $czam-primary,accent: $czam-accent)));
@include mat.button-color($czam-button-theme);






////////////////////////////////////////////////////////////////////////////////////////////////
// CUSTOM MATERIAL DESIGN COMPONENTS
// MAT BOTTOM SHEET
.mat-bottom-sheet-container {
  background-color: variables.$black3;
  border-radius: 20px 20px 0 0 !important;
  padding: 0 !important;
  @include screen("desktop") { box-shadow:  0px 0px 16px rgba(205,167,60,0.5) }
}
.cdk-overlay-dark-backdrop {
  background: rgba(0,0,0,0.75);
}
// MAT BUTTONS
.cta-primary, .cta-secondary {
  height: 56px !important;border-radius: 30px !important;
  max-height: 56px;
  .mdc-button__label {top: 1px}
}
.cta-primary{
  &:not(:disabled) {
    background: linear-gradient(268.35deg, #AF8716 9.25%, #CDA73C 29.74%, #AF8716 99.7%);
    color: #000 !important;
    &:hover {background: linear-gradient(268.35deg, #D5A732 9.25%, #E4BD22 29.74%, #F9AC16 99.7%)}
  }
  &:disabled {
    background: none !important;
    background-color: #A9A9A9 !important;
    color: #707475 !important;
  }
  &.mdc-fab {
    border: 0 !important;
    .material-icons {
      margin-right: 0 !important;
    }
  }
  &.denied-entry {
    background: linear-gradient(268.35deg, #ec5e41 9.25%, #FF6A4C 29.74%, #ec5e41 99.7%) !important;
    color: #000 !important;
  }
}
.cta-secondary {
  .mat-icon .mat-mdc-progress-spinner {
    --mdc-circular-progress-active-indicator-color: #707475
  }
  &:not(:disabled) {
    background-color: transparent !important;
    color: variables.$lightgray;
    border: 1px solid variables.$lightgray !important;
    &:hover {
      border: 1px solid variables.$primaryColor !important;
      color: variables.$primaryColor;
      background-color: transparent !important;
    }
  }
  &:disabled {
    background-color: transparent !important;
    border: 1px solid variables.$gray3 !important;
    color: variables.$gray2 !important;
  }
  &.mat-mdc-button, &.mat-mdc-unelevated-button { --mat-mdc-button-persistent-ripple-color:none}
  &.mdc-fab .material-icons {
      margin-right: 0 !important;
  }
  &.cta-warning {
    border-color: variables.$salmon !important; color: variables.$salmon !important;
    transition: all 0.2s;
    &:hover {
      background-color: variables.$salmon !important;
      border-color: variables.$salmon !important;
      color: variables.$black3 !important;
    }
    &:disabled {
      background-color: transparent !important;
      border: 1px solid variables.$gray3 !important;
      color: variables.$gray2 !important;       }
  }
}
.cta-tertiary {
  height: 30px !important;
  background-color: transparent !important;
  color: #FFF;
  border: 1px solid #FFF !important;
  transition: all 0.1s;
  font-size: 1rem !important;
  line-height: 1rem !important;
  &:disabled {
    background-color: transparent !important;
    border: 1px solid variables.$gray3 !important;
  }
  &:hover:not(:disabled) {
    border: 1px solid variables.$primaryColor !important;
    color: variables.$primaryColor;
    background-color: transparent !important;
  }
  &.cta-gold {
    background: linear-gradient(268.35deg, #AF8716 9.25%, #CDA73C 29.74%, #AF8716 99.7%);
    color: #000 !important;
    border: 1px solid variables.$primaryColor !important;
    gap: 5px;
    &:hover {background: linear-gradient(268.35deg, #D5A732 9.25%, #E4BD22 29.74%, #F9AC16 99.7%)}
  }
  &.cta-warning {
    padding: 0 30px;
    border-color: variables.$salmon !important; color: variables.$salmon !important;
    transition: all 0.2s;
    &:hover {
      background-color: variables.$salmon !important;
      border-color: variables.$salmon !important;
      color: variables.$black3 !important;
    }
    &:disabled {
      background-color: transparent !important;
      border: 1px solid variables.$gray3 !important;
      color: variables.$gray2 !important;
    }
  }
  &.mat-mdc-button, &.mat-mdc-unelevated-button { --mat-mdc-button-persistent-ripple-color:none}
  .mat-icon {height: 18px;width: 18px;margin-right: 10px;}
  .material-icons {font-size: 0.9rem;line-height: 18px;}
  .material-symbols-rounded {font-size: 0.9rem;margin-right: 10px; vertical-align: middle;}
}
.next-button {
  &:hover:not(:disabled) {
    background-color: variables.$black5 !important;
  }
  .mat-mdc-button-persistent-ripple {
    border-radius: 0 10px 10px 0 !important;
    &:hover {
      background-color: rgba(0,0,0,0.2) !important
    }
  }
}
.previous-button {
  &:hover:not(:disabled) {
    background-color: variables.$black5 !important;
  }
  .mat-mdc-button-persistent-ripple {
    border-radius: 10px 0 0 10px !important;
    &:hover {
      background-color: rgba(0,0,0,0) !important
    }
  }
}
.button-flex-row-icons .mdc-button__label {
  display: flex;
  flex-direction: row;
  gap: 10px;
  font-size: 0.8em;
  align-items: center;
}
// MAT BUTTONS FAB
.mat-mdc-fab.mat-accent, .mat-mdc-mini-fab.mat-accent {
  --mat-mdc-button-persistent-ripple-color: none
}
// MAT BOTTOM SHEET
.mat-bottom-sheet-container {
  background-color: variables.$black3;
  border-radius: 20px 20px 0 0 !important;
  padding: 0 !important;
  @include screen("desktop") { box-shadow:  0px 0px 16px rgba(205,167,60,0.5) }
}
.cdk-overlay-dark-backdrop {
  background: rgba(0,0,0,0.75);
}
// MAT CARD
.mat-mdc-card {
  &.customer-extended {
    border-width: 0;
    @include screen("phone") { padding: 0 0 20px !important;}
    .mat-mdc-card-header {
      @include screen("phone") { padding: 0 5px 10px !important;}
      .mat-mdc-card-header-text {
        display: flex !important;
        flex-direction: row !important;
        flex: 1;
        @include screen("phone") { flex-direction: column !important;}
        .mat-mdc-card-title {
          margin-bottom: 9px;
          @include screen("phone") { margin-bottom: 5px !important;}
        }
        .mat-mdc-card-subtitle {
          display: flex !important;
          align-items: center !important;
          gap: 5px;
        }
      }
    }
  }
}
// MAT DIALOG
.czam-dialog {
  background-color: rgba(0,0,0,0.7);
  opacity: 1 !important;
}
.czam-panel, .czam-panel-inform-dialog,
.czam-panel-centered, .czam-panel-centered-id-renew {
  border-radius: 20px;
  box-shadow:  0px 0px 16px rgba(205,167,60,0.5);
  .mat-mdc-dialog-container {
    .mat-mdc-dialog-surface {
      background-color: variables.$black2;
      .logo-dialog {
        width: 100px;
        //margin: 30px 0;
        @include screen("phone") {
          width: 70px;
          //margin: 20px 0;
        }
      }
    }
    border-radius: 20px !important;
    div {
      display: flex;
      justify-content: center;
      border-radius: 20px;
      p {font-size: 1rem}
      .mat-mdc-button .mat-button-focus-overlay {opacity: 0 !important}
    }
  }
}
.czam-panel-mobile-picture {
  .mat-mdc-dialog-container {
    .mat-mdc-dialog-surface {
      display: flex;
      align-items: center;
      justify-content: center;
      img { width: 100%;}
    }
  }


  @include screen ("phone") {
    height: 100% !important;
    width: 100% !important;
    max-width: none !important;
    max-height: none !important;
    .mat-mdc-dialog-container {
      .mat-mdc-dialog-surface {
        display: flex;
        background-color: variables.$black3;
        align-items: center;
        justify-content: center;
        img { width: 100%;}
      }
    }
  }
}
.czam-panel-centered, .czam-panel-centered-id-renew {
  .mat-mdc-dialog-container .mdc-dialog__container {
    display: flex;
    justify-content: start;
    .mat-mdc-dialog-surface {
      padding: 50px 20px 20px;
      @include screen ("phone") {
        padding: 10px 10px 0;
      }
      .material-icons {
        text-align: center;
      }
      h1 {
        text-align: center;
        font-size: 1.8rem;
        font-family: lucien-regular;
        color: variables.$lightgray;
        margin-bottom: 30px;
        @include screen("phone") {
          margin: 0 10px 15px;
        }
      }
      h2 {
        text-align: center;
        font-size: 1.1rem;
        line-height: 1.5rem;
        font-family: lucien-book;
        color: variables.$gray;
      }
      .mat-mdc-dialog-content {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        @include screen("phone") {
          font-size: 0.9rem;
          text-align: center;
        }
      }
      .mat-mdc-dialog-actions {
        display: flex;
        justify-content: center;
        .mdc-button {
          flex: 1;
        }
      }
    }
  }
}
.czam-panel-centered .mat-mdc-dialog-container .mdc-dialog__container {
  .mat-mdc-dialog-surface .mat-mdc-dialog-content {
    @include screen("phone") { padding: 25px 10px !important }
  }
}
.czam-panel-centered-id-renew {
  .mat-mdc-dialog-surface {
    @include screen ("phone") {
      padding: 40px 10px 20px !important;
      h1 { font-size: 1.5rem !important; }
      h2 { font-size: 1rem !important;
        strong { color: variables.$lightgray !important; }
      }
      .mat-mdc-dialog-content { padding: 5px 10px }
    }
  }
}
.czam-panel-inform-dialog {
  .mat-mdc-dialog-container .mdc-dialog__container {
    align-items: center;
    justify-content: center;
    .mat-mdc-dialog-surface {
      display: flex;
      align-items: center;
      justify-content: center;
      h1 {
        font-family: lucien-book, serif;
        color: variables.$primaryColor;
        font-size: 1.7rem;
        text-align: center;
        margin: 0 0 20px;
        @include screen("phone") {  margin-bottom: 10px }
      }
      .mat-mdc-dialog-content {
        @include screen("phone") {  padding: 18px }
        p {
          padding: 0 20px;
          font-size: 1rem;
          line-height: 1.4rem;
          strong {
            font-family: lucien-regular;
            color: variables.$lightgray !important;
          }
          @include screen("phone") {
            padding: 0 10px;
            font-size: 0.9rem;
            line-height: 1.2rem;
          }
        }
      }
    }
  }
}
// MAT EXPANSION
.mat-expansion-panel-content {
  overflow: hidden !important;
}
// MAT ICONS
.cta-primary .mat-icon,
.cta-secondary .mat-icon {
  margin-top: -5px;
}
.cta-primary .material-icons,
.cta-secondary .material-icons {
  font-size: 24px;
  line-height: 24px;
  vertical-align: middle;
  margin-right: 5px;
}
.cta-primary .material-icons-outlined,
.cta-secondary .material-icons-outlined {
  margin-right: 5px;
  vertical-align: middle;
  font-size: 1.2rem;
}
.mat-mdc-unelevated-button>.mat-icon,
.mat-mdc-raised-button>.mat-icon,
.mat-mdc-outlined-button>.mat-icon {
  width: auto !important;
  height: auto !important;
  margin-top: 2px;
}
// MAT INPUT FIELD
.mat-form-field-appearance-fill {
  .mdc-text-field--disabled.mdc-text-field--filled {
    background-color: transparent !important;
    border: 1px solid variables.$gray6;
    .mat-mdc-input-element {
      color: variables.$lightgray;
    }
  }
  &.red-border-if-matched {
    .mat-mdc-text-field-wrapper {
      border: 1px solid variables.$salmon !important;
    }
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px variables.$gray4 inset !important;
  -webkit-box-shadow: 0 0 0 30px variables.$gray4 inset !important;
  color: variables.$lightgray;
}
.form-container .login-form .mat-mdc-form-field {
  .mat-mdc-text-field-wrapper {
    border: 1px solid variables.$gray6;
  }
  &.mat-focused {
    .mat-mdc-text-field-wrapper {
      border: 1px solid variables.$primaryColor;
    }
  }
}
// MAT FORM FIELD
.mat-mdc-form-field {width: 100% !important}
.mat-form-field-appearance-fill .mdc-line-ripple {display: none}
.mdc-text-field--filled:not(.mdc-text-field--disabled) {background-color: variables.$gray5}
.mat-mdc-form-field-focus-overlay {background-color:rgba(255, 255, 255, 0.3)}
.mat-form-field-appearance-fill .mdc-text-field {
  border-radius: 16px !important;
  background-color: variables.$gray6;
}
.form-container .mat-mdc-form-field .mat-mdc-form-field-subscript-wrapper {height: 10px}
.birthday-filter .mat-mdc-form-field .mat-mdc-form-field-subscript-wrapper {display: none}
// MAT LIST
.mat-mdc-list {
  &.customer-extended-menu-list .mdc-list-item__content .mdc-list-item__primary-text {
    display: flex;
  }
}
// MAT RADIO
.mat-mdc-radio-button .mdc-form-field label {font-family: lucien-book;color: variables.$gray; cursor: pointer;}
.mat-mdc-radio-button { &.mat-mdc-radio-checked .mdc-form-field label {font-family: lucien-regular;color: #FFF}}

// MAT PROGRESS BAR
.wizard-progress-bar-bottom {
  z-index: 2000;
  position: fixed !important;
  top: 0;
}
// MAT SELECT
.step-subcontainer .mat-mdc-select .mat-mdc-select-trigger
{
  @include screen("desktop") {
    .mat-mdc-select-value {
     // margin-top: 10px;
    }
    .mat-mdc-select-arrow-wrapper {
     // margin-top: 13px;
    }
  }
}

.mat-mdc-form-field-type-mat-select {
  .mat-mdc-text-field-wrapper {
    // padding: 0 15px 2px;
    // margin-top: 0px;
    border-radius: 20px !important;
    // .mat-mdc-form-field-flex .mat-mdc-form-field-infix { padding-top: 10px }
    .mdc-line-ripple { display: none; }
    .mat-mdc-option .mdc-list-item__primary-text {letter-spacing: 0 }
  }
  &.mat-select-sort {
    height: 55px;
    .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix {
      padding-top: 5px;
      .mat-mdc-select-value-text {font-size: 0.9rem}
    }
  }
  &.mat-select-casino {
    .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix {
      padding-top: 15px;
      .mat-mdc-select-value-text {padding-left: 5px; padding-right: 5px;}
    }
  }
}
.field-container {
  .mat-mdc-select-disabled .mat-mdc-select-value {
    color: variables.$lightgray;
  }
  .mat-mdc-select-arrow-wrapper {
    padding-top: 5px !important;
  }
}
.transaction-history-casino-select .mat-mdc-select-trigger {
    padding-top: 10px !important;
}
.mat-mdc-select-panel { border-radius: 16px !important}

.mat-mdc-select {
  @include screen("phone") {  margin-top: 10px; }
  @include screen("phone") { .mat-mdc-select-trigger .mat-mdc-select-arrow-wrapper{margin-top: 3px;}  }
}

.mat-mdc-select-arrow svg {
	fill: variables.$primaryColor !important
}
// MAT SLIDE TOOGLE
#signature .mat-mdc-slide-toggle .mdc-form-field
  {
    button {
      margin-right: 20px;
    }
    label {
      color: variables.$lightgray;
      font-size: 1rem;
      line-height: 1.5rem;
      padding-left: 20px;
      a { color: variables.$primaryColor}
    }
}
.mat-mdc-slide-toggle {
  .mdc-form-field {
    flex-direction: row-reverse;
    display: flex;
    gap: 10px;
    label {
      cursor: pointer;
      font-size: 1.1rem;
      color: variables.$gray;
    }
    &:hover label {
      color: variables.$lightgray;
    }
  }
  &.non-reverse .mdc-form-field {
    display: flex;
    flex-direction: row;
  }
  &.mat-mdc-slide-toggle-checked label {
    color: white;
  }
}
// MAT SNACKBAR
.mat-mdc-snack-bar-container {
  border-radius: 22px !important;
  flex: 1;
  justify-content: center;
  display: flex;
  background: variables.$gray6 ;
  color: variables.$white;
  .mdc-snackbar__surface {
    border-radius: 20px !important;
    background-color: variables.$gray6 !important;
  }
  .mdc-snackbar__label {
    color: variables.$lightgray !important;
  }
}
.dark-snackbar-success {border: 3px solid variables.$green;}
.dark-snackbar-error {border: 3px solid variables.$red;}
.snackbar-border-salmon {border: 3px solid variables.$salmon;}
// MAT TAB
.mdc-tab {
  &.mdc-tab--active {
    .mdc-tab__content .mdc-tab__text-label {
      color: variables.$lightgray;
    }
  }
}
.mat-tab-label, .mat-mdc-tab-link {
  font-weight: 400 !important;
 }
 .mat-tab-label {
   font-family: lucien-regular, serif;
   font-size: 0.9rem;
   opacity: 1 !important;
   font-weight: 400 !important;
   color: variables.$gray;
 }
 .tab-cards .mat-mdc-tab-group .mat-mdc-tab-body-wrapper
 .mat-mdc-tab-body .mat-mdc-tab-body-content {
  @include screen("desktop") {
    overflow: hidden !important;
  }
 }
.mobile-custom-tab-header .mat-mdc-tab-group .mat-mdc-tab-header {
  @include screen("phone") {display: none}
}
////////////////////////////////////////////////////////////////////////////////////////////////









////////////////////////////////////////////////////////////////////////////////////////////////
/// ANGULAR MATERIAL FROM V14
.mat-form-field-appearance-outline .mat-form-field-outline {
  background: variables.$gray6  !important;
  box-sizing: border-box !important;
}
.mat-form-field-appearance-outline .mat-form-field-outline-start,
.mat-form-field-appearance-outline .mat-form-field-outline-end {
  border-radius: 0 !important;
}
.mat-checkbox-inner-container {
  background-color: variables.$gray6;
  border-radius: 3px;

  .mat-checkbox-frame {
    border: 0 !important;
  }
}
.mat-form-field-appearance-outline .mat-form-field-outline-start,
.mat-form-field-appearance-outline .mat-form-field-outline-end {
  border-radius: 0 !important;
}
.mat-form-field-appearance-fill.mat-form-field-disabled {
  .mat-form-field-flex {
    background-color: transparent;
    border: 1px solid variables.$gray6;
  }
}
.mat-input-element:disabled {
  color: variables.$white;
  font-size: 18px;
  font-family: lucien-light, serif;
}
.mat-checkbox-inner-container {
  background-color: variables.$black1;
  border-radius: 3px;

  .mat-checkbox-frame {
    border: 0 !important;
  }
}
.mat-checkbox-checkmark-path {
  stroke: #A9A9A9 !important;
}

.mat-error { color: variables.$salmon }
.mat-form-field-wrapper-pb0 .mat-form-field-wrapper {
  padding-bottom: 0 !important;
}

.mat-radio-label-content {
  font-family: lucien-light, serif;
}
.mat-radio-checked .mat-radio-label-content {
  font-family: lucien-regular, serif !important;
}
.mat-form-field-label {
  font-family: lucien-light, serif;
}

.mat-icon-button-missing-fields {
  margin: 5px 5px 0 10px !important;
  &.mat-button-disabled {
    background-color: transparent !important;
  }
}
.mat-mdc-fab, .mat-mdc-mini-fab {
  background-color: transparent !important;
  box-shadow: none !important;
  border: 1px solid white !important;
  &:hover {
    border: 1px solid variables.$primaryColor !important;
    color: variables.$primaryColor;
  }
  &.green {
    border: 1px solid variables.$green !important;
    color: variables.$green !important;
  }
  &.red {
    border: 1px solid variables.$salmon !important;
    color: variables.$salmon !important;
  }
}
.mat-fab-no-stroke {
  border: 0px solid #666 !important;
  color: black !important;
}
.mat-fab-no-stroke.mat-button-disabled {
  background: variables.$gray !important;
  color: #707475 !important;
}
.mat-mdc-unelevated-button.mat-warn, .mat-mdc-raised-button.mat-warn, .mat-mdc-fab.mat-warn, .mat-mdc-mini-fab.mat-warn {
  background-color: variables.$salmon !important;
  border: 1px solid variables.$salmon !important;
  color: black;
  &:hover {
    border: 1px solid variables.$salmon !important;
    color: black;
  }
}
.mat-mdc-unelevated-button.mat-primary, .mat-mdc-raised-button.mat-primary, .mat-mdc-fab.mat-primary, .mat-mdc-mini-fab.mat-primary {
  background-color: variables.$primaryColor !important;
  border: 1px solid variables.$primaryColor !important;
  color: black;
  &:hover {
    border: 1px solid variables.$primaryColor !important;
    color: black;
  }
}
////////////////////////////////////////////////////////////////////////////////////////////////







////////////////////////////////////////////////////////////////////////////////////////////////
// Colors
.red { color: variables.$red }
.salmon { color: variables.$salmon !important }
.redsalmon { color: variables.$salmon }
.gold { color: variables.$primaryColor !important}
.golden { color: variables.$primaryColor}
.white { color: #FFF !important}
.white-60-opacity { color: variables.$white-60-opacity !important}
.blue { color: variables.$blue }
.gray1 { color: variables.$gray; }
.green { color: variables.$green !important}
.color-gray3 { color: variables.$gray3 !important }
.color-lightgray { color: variables.$lightgray !important }
// Background Colors
.bg-white { background-color: #FFF !important; }
.bg-red { background-color: variables.$red; }
.bg-gold { background-color: variables.$primaryColor !important; }
.bg-salmon { background-color: variables.$salmon !important; }
.bg-blue { background-color: variables.$blue }
.bg-silver { background-color: variables.$gray }
.bg-orange { background-color: variables.$orange }
.bg-im { background-color: variables.$red}
.bg-gelav { background-color: variables.$blue}
.bg-anpr { background-color: variables.$salmon}
.bg-lva { background-color: variables.$yellow}
.bg-homo { background-color: variables.$orange }
.bg-enter, .bg-green { background-color: variables.$green !important; }
.bg-ap { background-color: variables.$gray }
.goldgrad {
  background: linear-gradient(268.35deg, #AF8716 9.25%, #CDA73C 29.74%, #AF8716 99.7%);color: #000;
  &:hover {background: linear-gradient(268.35deg, #D5A732 9.25%, #E4BD22 29.74%, #F9AC16 99.7%)}
}
// Border Colors
.border-orange { border-color: variables.$orange !important}
.border-green { border-color: variables.$green !important}
.border-im { border-color: variables.$red !important }
.border-gelav { border-color: variables.$blue !important}
.border-ap { border-color: variables.$gray !important }
.border-lva { border-color: variables.$yellow !important }
.border-homo { border-color: variables.$orange !important }
.border-enter { border-color: variables.$green !important }
.border-lightgray { border-color : variables.$gray6 !important}
.border-anpr, .border-salmon { border-color: variables.$salmon  !important }
.border-left-white { border-left: 1px solid #FFF }
// Single Style Classes
.txt-strong { font-family: lucien-regular; color: white; }
.txt-strong-lightgray {font-family: lucien-regular; color: variables.$lightgray !important;}
.br-10 { border-radius: 10px;}
.cursor-pointer { cursor: pointer }
.border-1 { border: 1px solid }
.text-light { font-family: lucien-light }
.text-normal { font-family: lucien-book }
.text-bold { font-family: lucien-bold }
.strong-inside-light { strong {font-family: lucien-regular}}
.uppercase { text-transform: uppercase }
.capitalize { text-transform:capitalize }
.desktop-mt--5 { @include screen("desktop"){ margin-top: -25px !important } }
.desktop-mt-20 { @include screen("desktop"){ margin-top: 20px !important } }
.desktop-mt-40 { @include screen("desktop"){ margin-top: 40px !important } }
.desktop-mt-75 { @include screen("desktop"){ margin-top: 75px !important } }
.desktop-ml-5 { @include screen("desktop"){ margin-left: 5px !important } }
.desktop-mr-20 { @include screen("desktop"){ margin-right: 20px !important } }
.desktop-mw-1000 { @include screen("desktop"){ max-width: 1000px}}
.desktop-mw-500 { @include screen("desktop"){ max-width: 500px}}
.desktop-mw-400 { @include screen("desktop"){ max-width: 400px}}
.desktop-mb-30 { @include screen("desktop"){ margin-bottom: 30px !important }}
.desktop-ml-10 { @include screen("desktop"){margin-left: 10px}}
.desktop-pt-120 { @include screen("desktop"){padding-top: 120px !important} }
.phone-pt-100 { @include screen("phone"){ padding-top: 100px !important } }
.phone-mt-100 { @include screen("phone"){ margin-top: 100px !important } }
.phone-mt-40 { @include screen("phone"){ margin-top: 40px !important } }
.phone-mt-0 { @include screen("phone"){ margin-top: 0px !important } }
.m-0 { margin: 0 !important }
.m-auto { margin: auto !important;}
.mt-0 { margin-top: 0px !important }
.mt-5 { margin-top: 5px !important }
.mt-10 { margin-top: 10px !important }
.mt-20 { margin-top: 20px !important }
.mt-30 { margin-top: 30px !important }
.mt-40 { margin-top: 40px !important }
.mt-50 { margin-top: 50px !important }
.mt-80 { margin-top: 50px !important }
.mt-100 { margin-top: 100px !important}
.mt-200 { margin-top: 200px !important}
.ml-0 { margin-left: 0px !important}
.ml-5 { margin-left: 5px}
.ml-10 { margin-left: 10px}
.ml-30 { margin-left: 30px}
.mr--10 { margin-right: -10px !important;}
.mr-5 { margin-right: 5px}
.mr-10 { margin-right: 10px}
.mr-20 { margin-right: 20px}
.mb-10 { margin-bottom: 10px}
.mb-20 { margin-bottom: 20px}
.mb-30 { margin-bottom: 20px}
.mt-20 { margin-top: 20px}
.mt-50 { margin-top: 50px}
.mb-5 {margin-bottom: 5px !important}
.mb-10 {margin-bottom: 10px !important}
.mb-20 {margin-bottom: 20px !important}
.mb-40 {margin-bottom: 40px !important}
.my-20 { margin-right: 20px; margin-left: 20px;}
.mw-350 { min-width: 350px !important;}
.mw-1100 { max-width: 1100px !important;}
.py-20 { padding-right: 20px !important; padding-left: 20px !important;}
.py-50 { padding-right: 50px !important; padding-left: 50px !important;}
.py-80 { padding-right: 80px !important; padding-left: 80px !important;}
.pt-5 { padding-top: 5px }
.pt-7 { padding-top: 7px }
.pt-20 { padding-top: 20px}
.pt-30 { padding-top: 30px}
.pr-5 { padding-right: 5px }
.pl-20 { padding-left: 20px }
.pr-20 { padding-right: 20px }
.lh-17px { line-height: 17px }
.gap-100 { gap: 100px }
.gap-16 { gap: 16px }
.gap-10 { gap: 10px }
.center { text-align: center}
.text-left { text-align: left !important}
.hide-mobile { @include screen("phone"){ display: none !important } }
.hide-desktop { @include screen("desktop"){ display: none !important } }
.pt-app-container { padding-top: variables.$paddingTopAppContainer !important }
.w-1000 { width: 1000px !important }
.w-900 { width: 900px !important }
.w-25 { width: 25px}
.w-full { width: 100%;}
.w-80 { width: 80%;}
.w-150px { width: 150px;}
.w-170px { width: 200px;}
.h-auto { height: auto !important;}
.h-100vh { height: 100vh !important;}
.mw-900 { max-width: 900px !important }
.mh-100 { min-height: 100% !important;}
.fx-row { display: flex; flex-direction: row !important;}
.fx-col { display: flex; flex-direction: column !important;}
.fx-jc-start { display: flex; justify-content: flex-start !important;}
.fx-jc-center { display: flex; justify-content: center !important;}
.mobile-fx-ai-center { @include screen("phone") {display: flex; align-items: center !important; }}
.fx-jc-sb { display: flex; justify-content: space-between !important;}
.fx-gap-50 { display: flex; gap: 50px}
.fx-ai-center { display: flex; align-items: center;}
.fx-ai-end { display: flex; align-items: flex-end; }
.white-space-no-wrap { white-space: nowrap !important;}
////////////////////////////////////////////////////////////////////////////////////////////////








////////////////////////////////////////////////////////////////////////////////////////////////
// SPECIFIC PAGES
.show-homepage-bg {
  background-image: url("../../assets/lib-common/images/bg-homepage.png");
  background-size: contain;
  background-position: center bottom;
  background-repeat: no-repeat;
  @include screen("phone") {
    background-image: none;
  }
}
////////////////////////////////////////////////////////////////////////////////////////////////


/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */

/* total width */
::-webkit-scrollbar {
  background-color: rgb(0,0,0,0);
  width: 10px;
  height: 10px;
}

/* background of the scrollbar except button or resizer */
::-webkit-scrollbar-track {
  background:none
}

::-webkit-scrollbar-track:hover {
  background: none;
}

/* scrollbar itself */
::-webkit-scrollbar-thumb {
  background-color: #686868;
  border-radius: 16px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #808080;
}

/* set button(top and bottom of the scrollbar) */
::-webkit-scrollbar-button {
  display: none
}

/* Common fadeIn animation */
@keyframes defaultShow {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

// Setting the material fonts via the local files
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url('../../assets/lib-common/fonts/material-icon-regular.woff2') format('woff2');
}
/* fallback */
@font-face {
  font-family: 'Material Icons Outlined';
  font-style: normal;
  font-weight: 400;
  src: url('../../assets/lib-common/fonts/material-icon-outlined.woff2') format('woff2');
}
/* fallback */
@font-face {
  font-family: 'Material Symbols Rounded';
  font-style: normal;
  font-weight: 400;
  src: url('../../assets/lib-common/fonts/material-icon-rounded.woff2') format('woff2');
}

.material-icons {
  font-family: 'Material Icons', serif;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

.material-icons-outlined {
  font-family: 'Material Icons Outlined', serif;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

.material-symbols-rounded {
  font-family: 'Material Symbols Rounded', serif;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

.czam-panel-add-list .mat-mdc-dialog-container .mdc-dialog__surface {
  border-radius: 20px !important;
}

.invalid-client-reason-dialog {
  border-radius: 20px;
  box-shadow:  0px 0px 16px rgba(205,167,60,0.5);
  .mat-mdc-dialog-container {
    .mat-mdc-dialog-surface {
      padding: 30px;
      background-color: variables.$black2;
      .mat-mdc-dialog-actions {
        display: flex;
        justify-content: center;
        .mdc-button {
          flex: 1;
        }
      }
      h1 {
        font-family: 'lucien-light', serif;
        color: variables.$white;
        margin-bottom: 30px;
      }
      h2 {
        font-size: 1.2rem;
        font-family: 'lucien-light', serif;
        color: variables.$lightgray;
        margin-bottom: 30px;
      }
    }
    border-radius: 20px !important;
    div {
      display: flex;
      p {
        font-size: 1rem;
        font-family: 'lucien-light', serif;
        color: variables.$lightgray;
      }
      .mat-mdc-button .mat-button-focus-overlay {opacity: 0 !important}
    }
  }
}

.contract-dialog, .czam-panel-inform-dialog,
.czam-panel-centered, .czam-panel-centered-id-renew {
  display: flex;
  justify-content: center;
  border-radius: 20px;
  box-shadow:  0px 0px 16px rgba(205,167,60,0.5);
  .mat-mdc-dialog-container {
    .mat-mdc-dialog-surface {
      background-color: variables.$black2;
    }
    border-radius: 20px !important;
  }
}

.hotel-reservation-tabs {
  .mat-mdc-tab-label-container {
    flex-grow: 0;
  }
}

.flex-1 {
  flex: 1;
}
