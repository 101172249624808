/* You can add global styles to this file, and also import other style files */
@use "./variables";
@import "mixin.scss";
@import "theme.scss";


html,
body {
  margin: 0;
  font-family: 'lucien-regular', serif !important;
  height: 100%;
  padding: 0;
  position: absolute;
  width: 100%;
  display: flex;
}

html {
  height: 100%;
}

body {
  min-width: 375px;
  min-height: 100% !important;
  background-image: url("../../assets/lib-common/images/grille_etoiles_background.svg");
  background-repeat: repeat;
}

.offline-border {
  border: 5px solid variables.$orange;
  box-sizing: border-box;
}

.offline-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: variables.$orange;
  color: variables.$black2;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -2%);
  width: 179px;
  height: 34px;
  padding: 6px 10px 6px 10px;
  gap: 10px;
  border-radius: 0 0 8px 8px;
}

.offline-hidden {
  visibility: hidden;
  height: 0;
}

.offline-icon {
  width: 16px !important;
  height: 16px !important;
  font-size: 16px !important;
}

.offline-title {
  font-family: lucien-regular, sans-serif;
  font-size: 18px;
  font-weight: 400;
}

* {
  box-sizing: border-box;
}

a, a:-webkit-any-link {
  color: variables.$primaryColor;
}

.split-container {
  & > .left {
    background-color: variables.$black5;
    @include screen("phone") {
      padding: 30px;
    }
  }

  & > .right {
    background-color: variables.$black2;
    @include screen("phone") {
      background-color: variables.$black5;
      padding: 30px;
    }
  }
}

@include screen("desktop") {
  .split-container {
    display: flex;
    height: 100%;
    width: 100%;

    & > .left {
      width: 25vw;
      max-width: 450px;
      min-width: 300px;
      overflow-y: auto;
      // min-height: 100vh;
      padding: 30px;
      background-color: variables.$black5;
    }

    & > .right {
      flex: 1;
      padding: 30px;
      overflow-y: auto;
      position: relative;
      background-color: variables.$black2;
    }
  }

  .classic-container {
    padding-top: variables.$paddingTopAppContainer !important;
  }
}

.white-spinner.mat-mdc-progress-spinner circle {
  stroke: #fff !important;
}

.big-dialog {
  @include screen("phone") {
    height: 100vh;
    width: 100vw !important;
    max-width: inherit !important;
    min-width: inherit !important;
  }
  min-width: 600px;
}

.hide-item-desktop {
  display: none;

  @include screen("phone") {
    display: initial;
  }
}

.mat-mdc-button,
.mat-mdc-unelevated-button,
.mat-mdc-icon-button,
.mat-mdc-outlined-button {
  border-radius: 30px !important;
}

.interdit-ministeriel {
  background-color: variables.$red;
  color: #fff;

  .mat-divider {
    border-color: variables.$lightgray;
  }
}

.default-client-bloc {
  background-color: variables.$gray6;
  color: variables.$lightgray;

  .mat-divider {
    border-color: variables.$lightgray;
  }
}

.anpr {
  background-color: variables.$salmon
}

.gel-avoir {
  background-color: variables.$blue
}

.ap {
  background-color: variables.$gray
}

.lva {
  background-color: variables.$yellow
}

.lva,
.ap,
.gel-avoir,
.anpr {
  color: variables.$black5;

  .mat-divider {
    border-color: variables.$black5;
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
.mat-slide-toggle-bar {
  order: 2;
}

/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
.mat-slide-toggle-content {
  order: 1;
  flex: 1;
  padding-left: 11px;
}

.mat-mdc-slide-toggle {
  width: 100%;
  margin-top: 8px;
}


@include screen("phone") {

  /** Basic angular typo for mobile **/
  .mat-typography {
    h1 {
      font-weight: 400;
      font-size: 24px;
      line-height: 28px;
      color: variables.$lightgray;
      margin-top: 30px;
    }
  }

  .mat-form-field-wrapper {
    padding-bottom: 0 !important;
  }
}

@include screen("desktop") {
  .mat-mdc-card {
    &.customer-extended {
      /* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
      .mat-card-header-text {
        display: flex;
        flex: 1;
      }
    }
  }
}

@include screen("phone") {
  .mat-mdc-card {
    &.customer-extended {
      /* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
      .mat-card-header-text {
        flex: 1;
      }
    }
  }
}


/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px variables.$gray6 inset !important; // Hack for auto fill background color
  box-shadow: 0 0 0 30px variables.$gray6 inset !important;
  transition: background-color 5000s ease-in-out 0s; // Hack to remove auto fill background color
  -webkit-text-fill-color: variables.$gray !important;
}

input:disabled {
  background-color: transparent !important;
}

.pointer {
  cursor: pointer;
}

.center-content-xy {
  align-items: center;
  justify-content: center;
}

.auto-size {
  height: auto !important;
  width: auto !important;
}


@media screen {
  .printPictureOnly {
    display: none;
  }

  .printableCNILDocument {
    height: auto;
    display: none;
  }
}


@media print {
  @page {
    // This will set up the default values for the printing
    margin: 5mm 5mm 5mm 5mm;
    size: A4;
    background: white !important;
  }

  .mat-app-background {
    background-color: white !important;
  }
  .mat-drawer-container {
    background-color: white !important;
  }
  /** Hide all the unnecessary other component **/
  #matToolbar {
    display: none;
  }

  #leftDetailedPage {
    display: none;
  }

  #backButton2 {
    display: none;
  }

  #customerExtendedRightPageMatTabGroup {
    .mat-mdc-tab-header {
      display: none;
    }
  }

  #customerExtendedRightPageMatList {
    display: none;
  }

  .zoom-pic-icon {
    display: none !important;
  }

  #screenPart {
    display: none;
  }

  .header {
    display: none !important;
  }

  /** Start of the CNIL printable document **/
  .printableCNILDocument {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: variables.$gray3;

    /** Common CSS for all the parts **/
    .label {
      font-family: 'lucien-regular', serif;
      text-align: left;
    }

    .data {
      text-align: left;
      width: 44%;
    }

    .subTitle {
      font-family: 'lucien-bold', serif;
      font-size: 18px;
      text-align: left;
      text-transform: uppercase;
      color: variables.$black5;
    }

    .subTitle--padding-bottom {
      padding-bottom: 15px;
    }

    .paddingTopBottom15 {
      padding-bottom: 15px;
      padding-top: 15px;
    }

    /** Used in the picture component app-image-with-zoom **/
    .printPictureOnly {
      display: block;
      border-radius: 16px;
      position: relative;
      cursor: pointer;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }

    #webPicture {
      display: none;
    }

    .print-profile-image {
      max-height: 213px;
      max-width: 213px;
      border-radius: 16px
    }

    /** Header: Company Name, logo page title **/

    .headerCNIL {
      align-self: center;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-content: center;
      align-items: center;
    }

    .logoCompany {
      width: 10%;
      gap: 8px;
      display: flex;
      align-content: center;
      align-items: center;
    }

    .companyNameFormat {
      font-family: 'lucien-bold', serif;
      width: 100px;
      font-size: 20px;
      text-transform: uppercase;
    }

    .pageTitle {
      width: 80%;
      display: flex;
      justify-content: center;

      span {
        font-family: 'lucien-bold', serif;
        font-size: 22px;
        text-align: center;
        text-transform: uppercase;
        color: #AF8716
      }
    }

    /** First part Costumer Details + photo **/
    .customerDetails {
      @extend .paddingTopBottom15;
      display: flex;
      align-items: center;
      width: 100%;
      flex-direction: row;
      border-bottom: 1px solid #cda73c;
    }

    #customerDetailsContent {
      div {
        display: flex;
        justify-content: space-between;
      }
    }

    /** Costumer Address **/
    #customerAddress {
      @extend .paddingTopBottom15;
      display: flex;
      width: 100%;
      flex-direction: column;
      border-bottom: 1px solid #cda73c;
    }

    #customerAddressContent {
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
    }

    #customerAddressLeftContent {
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      align-content: space-between;
      flex-wrap: wrap;

      div {
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
      }
    }

    #customerAddressRightContent {
      width: 40%;
      display: flex;
      flex-direction: column;

      div {
        display: flex;
        width: 80%;
        flex-direction: row;
        justify-content: space-between;
      }

    }

    /** Costumer ID and Company Card **/
    #customerIdAndDetailsCompanyCard {
      @extend .paddingTopBottom15;
      display: flex;
      width: 100%;
      flex-direction: row;
      border-bottom: 1px solid #cda73c;
      justify-content: space-between;
    }

    #costumerIdPart {
      display: flex;
      width: 50%;
      flex-direction: column;
    }

    #costumerIdContent {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      align-content: space-between;
      flex-wrap: wrap;

      div {
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
      }
    }

    #companyCard {
      display: flex;
      width: 40%;
      flex-direction: column;
    }

    #companyCardContent {
      width: 100%;
      display: flex;
      flex-direction: column;

      div {
        display: flex;
        width: 80%;
        flex-direction: row;
        justify-content: space-between;
      }
    }

    .costumerDetailsContent--width {
      width: 50%;
    }

    /** Communication, Terms and Conditions and Signature **/
    #communication {
      width: 100%;
      padding-top: 10px;

      .checkBox {
        font-family: 'lucien-book', serif;
        display: flex;
        justify-content: space-evenly;
        padding-bottom: 5px;
        padding-top: 5px;

        div {
          display: flex;
          align-items: center;
        }
      }

      .receiveTextAndCheckBox {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-bottom: 10px;
      }
    }


    .receivePartnerCommunication {
      font-family: 'lucien-book', serif;
      font-size: 12px;
      text-align: left;
      color: variables.$black5;
    }

    .termsAndConditions {
      font-family: 'lucien-light', serif;
      font-size: 11px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0px;
      text-align: left;
      color: variables.$gray2;

      p {
        line-height: 12px;
        padding: 0;
      }
    }

    #signature {
      padding-top: 5px;
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: flex-start;
    }

    .leftSignature {
      display: flex;
      align-self: flex-start;
      flex-direction: row;
      align-items: flex-start;
      gap: 20px;
      width: 50%;
    }

    .paddingTopBottom15 {
      padding-bottom: 15px;
      padding-top: 15px;
    }


    .signaturePrintOnly {
      .img {
        height: 200px;
        width: 200px;
        border-radius: 16px;
      }

      display: block;
    }
  }
}


.mat-drawer-container {
  background-color: transparent;
  color: darkgray;

}

.mat-app-background {
  background-color: variables.$black6 !important;
}

.overflowY-auto {
  overflow-y: auto;
}

.flex-grow-1 {
  flex-grow: 1;
}

.padding-top-30 {
  padding-top: 30px;
}

// Disabling Pull-to-Refresh pageà problem for Signature Components
.no-refresh-on-swipe {
  overscroll-behavior: contain;
}

// TODO: This style do not apply when defined in the component !
.with-background-logo {
  background-image: url("../../assets/lib-common/images/logo_background.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.mat-mdc-tab-header {
  border-bottom: 1px solid #3C3C3C;
}

#optin {
  .mat-mdc-slide-toggle {
    width: auto;
  }

  .mat-mdc-slide-toggle .mdc-form-field {
    align-items: center;
  }
}

#search-form {
  .mdc-tab {
    min-width: 60px;
    border-radius: 14px;
    padding: 0;
  }

  .mat-tab-label:hover {
    border-radius: 50px;
  }

  .mdc-tab-indicator--active {
    background-color: variables.$primaryColor;

    span {
      color: variables.$black2;
    }
  }

  .mat-mdc-tab-header {
    border-radius: 16px;
    background-color: variables.$black2;
    --mdc-tab-indicator-active-indicator-height: 0;
    /* --mat-tab-header-inactive-ripple-color: red; */
    border-bottom: none;
    padding: 5px;
  }

  .mdc-switch__ripple {
    display: none;
  }

  .mdc-tab__ripple:hover {
    display: none;
  }
}

#customer-key-chip {
  .mat-mdc-chip {
    background-color: transparent;
    border: 1px solid variables.$gray;
  }

  .mdc-evolution-chip__icon--trailing {
    font-size: 14px;
    height: 14px;
    width: 14px;
  }

  .mat-mdc-chip.mat-mdc-standard-chip {
    height: 25px;
  }

  .mat-mdc-chip.mdc-evolution-chip--with-trailing-action .mat-mdc-chip-action-label {
    font-size: 12px;
    line-height: 25px;
    height: 100%;
  }
}

/** CUSTOMIZED TOOLTIPS **/

.transaction-tooltip .mdc-tooltip__surface {
  padding: 15px;
  background-color: variables.$black1;
  white-space: pre-line;
  text-align: left;
  font-family: 'lucien-light', serif;
  box-shadow: 0px 4px 11px 0px #00000073;
  border-radius: 10px;
}

.transaction-tooltip .mdc-tooltip__surface::first-line {
  font-family: 'lucien-regular', serif;
}

.lva-tooltip .mdc-tooltip__surface {
  padding: 15px;
  background-color: variables.$black1;
  white-space: pre-line;
  text-align: left;
  font-family: 'lucien-light', serif;
  box-shadow: 0px 4px 11px 0px #00000073;
  border-radius: 10px;
}

.jr-multiline-tooltip .mdc-tooltip__surface {
  white-space: pre-line;
  text-align: left;
  font-family: 'lucien-light', serif;
}

/** CUSTOMIZED TOOLTIPS **/

.form-field-error {
  .mat-mdc-text-field-wrapper {
    border: 1px solid variables.$salmon !important;
  }
}

.go-to-vdi-btn:hover .mat-icon svg g path {
  fill: #CDA73C;
}

.token-picto-white svg path {
  fill: variables.$white-60-opacity;
}

.search-result-container .mat-mdc-card.customer-extended .mat-mdc-card-header .mat-mdc-card-header-text {
  width: 75%;
  white-space: nowrap;
}

.primary-color {
  color: variables.$primaryColor
}

.flex-content-tab-body .mat-mdc-tab-body-wrapper {
  flex: 1;
}

.remove-mat-form-field-under-space {
  margin-bottom: -1.25em;
}

.reset-form-link {
  align-self: center;
  color: white;
  user-select: none;
  white-space: nowrap;

  &:active {
    transform: scale(0.9);
  }
}

.reset-form-link:hover {
  cursor: pointer;
  text-decoration: underline;
}
